* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
  cursor: default;
  user-select: none;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 4rem;
}

body {
  background-color: #171717
}